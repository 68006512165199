





/************ Custom Font ************/

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/rudaw.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}
/************ Default Css ************/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;


  /* Add the blur effect */
  
background-image: url('./assets/img/bgm.webp');
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;


}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  
}
p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background-color: transparent;
}
input:focus, texrea:focus, select:focus {
  outline: none;
}
@media (min-width:1700px) {
  main .container {
    max-width: 100%;



    padding: 0 150px;
  }
}
body {
  -webkit-overflow-scrolling: touch;
}


.banner{


  top: 0;
  left: 0;


 position: relative;
  background-position: top center;
width: 100%;
  height: 100vh;


} 

.designim img{
  height: 80vh;
  width: 100%;
  padding: 20px;
}
.de2{
  right: 0;
  top: 50vh;
}
.carousel-wrapper{

  width: 100%;

direction: ltr;
touch-action: auto;
-ms-touch-action: auto;
  height: 100vh;

  left: 0;
  top: 0;
  background-position: top center;
  background-size: cover;
object-fit: cover;
  background-repeat: no-repeat;


}
.carousel-wrapper img{

  width: 100%;
  height: 100vh;
  object-fit: cover;
  transition-duration: 1s;

 

}
@media screen and (max-width:768px){
  .carousel-wrapper{

    width: 100%;
    height: 70vh;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
  
  }
  .carousel-wrapper img{
    height: 70vh;
  object-fit: cover;

  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  
  }
  .banner{
  







    
    height: 70vh;
    margin-bottom: 30px;
  } 
  

}

@media screen and (max-width:480px){
  .carousel-wrapper{

    width: 100%;
    height: 70vh;

    background-repeat: no-repeat;
  
  }
  .carousel-wrapper img{
    height: 70vh;

    object-fit: cover;
  
  }
  .banner{



    height: 70vh;
  margin-bottom: 30px;
  } 
  

}
.bannerr h1 {
  
  font-size: 50px;
  font-weight: 900;
  letter-spacing: 0.8px;
  line-height: 1;
  
  padding: 0 15px;
  display: block;
  color: transparent;
  -webkit-text-stroke :1px #fff;
position: absolute;
align-items: center;
justify-content: center;



}
.viim{
  margin-top: 30px;
}
.abut button span{
   font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1; 
  padding: 0 15px;  display: block;
align-items: center;
justify-content: center;color: white;
}
.abut{
 
  
 


background-color:rgb(182, 132, 7) ;

border: 2px solid rgb(214, 201, 201);
padding: 0px 10px;

}
.abut :hover {
  background-color: #fff;


    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color:rgb(182, 132, 7);

}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}









/************ contact Css ************/
.contact {
  padding: 60px 0 200px 0;


}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content:center;
  color: transparent;
  -webkit-text-stroke :1px #fff;
  background: radial-gradient(ellipse farthest-corner at right bottom, #eae1bd 0%, #e0c796 8%, #9f7e35 30%, #f3cd74 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #574e3a 62.5%, #5d4a1f 100%);
  opacity: 0.8;
  border-radius: 25px;
}
.contact form input, .contact form textarea {
  width: 100%;
  border: 2px solid rgb(182, 132, 7);
  padding: 55px;
  border-radius: 20px;
  color: white;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color:black;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #616060;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: white;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  opacity: 0.7;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 25px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
 
}
.newsletter-bx {
  background: #FFFFFF;

  
  border-radius: 50px;

  border: 2px solid rgb(182, 132, 7);
padding: 55px;


 
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  
  border-radius: 15px;

  border: 2px solid rgb(198, 187, 160);
padding: 5px;

  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";

  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  opacity: 0.7;
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.logofot{
  width: 20%;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  opacity: 0.7;
 
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  opacity: 0.7;  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgb(198, 187, 160);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;align-items: center;
justify-content: center;
}
.social-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
justify-content: center;
 
}
.skill-bx {
  border-radius: 50px;

  border: 2px solid rgb(182, 132, 7);
padding: 15px;
  

  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;


}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
 
}
.skill h2,h5{
  color: rgb(60, 60, 60);
}
.skill p {
    color: #767474;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 60%;
  margin: 0 auto 15px auto;
  border-radius: 50%;
}



.filterItemm button {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  opacity: 0.7;
  color: #fff;
  padding: 8px 0px;
  text-transform: uppercase;
  font-weight: 600;
width: 100px;
  font-size: 15px;
  border-radius: 50px;
}
.filterItemm  button:hover {
  background: white;
  cursor: pointer;
  color: black;
  transition: all 1s ease-in-out;
}


  /* background-color: #000000cc; */
  .galleryItem img {
  width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .galleryContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

 
    
  }
  .galleryWrapper {
    margin-top: 70px;
display: flex;
flex-wrap: wrap;
    padding: 5% 0%;
    width: 100%;
    align-items: center;
    justify-content: center;

  }
  .filterItem ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    gap: 10px;
    margin-bottom: 30px;
  
  
  }
  .filterItem ul button {
    
    opacity: 0.7;
    color: #A57C00;
    padding: 8px 46px;
    text-transform: uppercase;


    font-size: 17px;
    border-radius: 50px;
  }
 
  






  
  .filterItem ul button:hover {
    background: white;
    cursor: pointer;
    color: black;
    transition: all 1s ease-in-out;
  }
  
  .galleryItem img {
    border-radius: 25px;
  }





  .slider-wraperr{
    position: relative;
    height: 30vh;
    width: 90%;
    margin: auto;
    display: grid;
    place-items: center;
    overflow: hidden;
   

  }
  .slider-track:hover{
    animation-play-state: paused;
  }
  .slider-track{
    display: flex;
    width: calc(250*6);
    animation: slidescroll 8s linear infinite;
  }
  @keyframes slidescroll{
0%{
  transform: translateX(0);
}
100%{
  transform: translateX(calc(-250px*1));
}
  }
  .slidee{
    width: 30vh;
    height: 30vh;
    display: flex;
    align-items: center;
    padding: 5px;
    overflow: hidden;
    perspective: 100px;
    cursor: pointer;
   
  }
  .slidee img{
max-width: 100%;
min-height: 100%;

object-fit: cover;


  }
  .slidee img:hover{

   transform: translateZ(20px);
  }
  .slider-wraperr::before,
  .slider-wraperr::after{
background:linear-gradient(to right left,rgba(255,255,255,1)0%,rgba(255,255,255,0)100%);
content: "";
position: absolute;
height: 100%;
width: 15%;
z-index: 2;
  }

  .slider-wraperr::before{
    left: 0;
    top: 0;

  }
  .slider-wraperr::after{
right: -2px;

top: 0;
transition: rotateZ(180deg);
  }
.abbod{
  margin-top: 14vh;
}
  .aboutt{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
  }
  .aboutt .aleft{
    text-align: center;
    margin:auto;
    padding: 1rem;
    max-width: 350px;
  
   
  }
  .aboutt .aleft p{
   
    margin:1.5rem 0;
    text-align:justify;
  
  }
  .abouttt  h1 {
  text-align: center;
   color: #a77303;
  
   font: normal 36px 'Cookie', cursive;
   font-size: 2rem;
  
  }
  .abouttt  h2 {
    text-align: center;
    color: black;
   
    font: normal 36px 'Cookie', cursive;
    font-size: 1.5rem;
    margin-bottom: 10px;
   
   }
  .aboutt .aright{
  
    max-width: 700px;

  } .visimgcon{
    margin-bottom: 60px;
    margin-top: 30px;
  }
   .aright .img-acontainer{
  
   display: grid;
   grid-template-columns: repeat(12,1fr);
   position: relative;
   align-items: center;
   text-align: center;
  }
 
  .aright img{
  max-width: 90%;
  border: 1px solid #333;
  }
  .aboutimage{
    width: 100%;
    height: 100%;
  }
  .arightlf{
    width: 100%;
    height: 150px;
  }
  .aright .top{
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 6;
  }
  .aright .bottom{
    grid-column: 4 /  -1;
    grid-row: 1;
   
    z-index: 5;
  }
  
.abouttt  h1 {
text-align: center;
 color: #A57C00;

 font: normal 36px 'Cookie', cursive;
 font-size: 2rem;

}
.abouttt  h2 {
  text-align: center;
  color: #2E1834;
 
  font: normal 36px 'Cookie', cursive;
  font-size: 1.5rem;
  margin-bottom: 10px;
 
 }
.image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.teama{
  width: 250px;
  background-color:  #a77303;
  padding:10px;
  border-radius: 25px;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.teama h2{
  color:white;
  margin:5px 0px;
}
.teama h3{
  color:white;
  margin: 7px 0px;
}
.SocialMediaIcon{
  color:white;
  margin:20px 0px;
}
.icon{
  margin:0px 5px;
}

/*hover effect*/
.teama:hover{
  animation: flip 1s;
  background-color:  #a77303;
}
@keyframes flip {
  from{
      transform: rotate(0);
  }
  to{
      transform: rotateY(360deg);
  }
  
}
.galleryItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.galleryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media only screen and (max-width: 1024px) {

  .filterItem ul {
   display: flex;
  flex-wrap: wrap;
   }
}
@media only screen and (max-width: 912px) {

  .filterItem ul {
   display: flex;
  flex-wrap: wrap;
   }
}
@media only screen and (max-width: 820px) {

  .filterItem ul {
   display: flex;
  flex-wrap: wrap;
   }
}
@media only screen and (max-width: 768px) {

  .filterItem ul {
   display: flex;
  flex-wrap: wrap;
   }
}
@media only screen and (max-width: 540px) {

  .filterItem ul {
   display: flex;
  flex-wrap: wrap;
   }
}
@media only screen and (max-width: 414px) {

   .filterItem ul {
    display: flex;
   flex-wrap: wrap;
    }
}
@media only screen and (max-width: 393px) {

  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;}
    .galleryItem  {
     width:150px;
     height: 200px;
     object-fit: cover;
   }
   .filterItem ul {
    display: flex;
   flex-wrap: wrap;
    }
}
@media only screen and (max-width: 375px) {

  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;}
    .galleryItem  {
     width:140px;
     height: 200px;
     object-fit: cover;
   }
   .filterItem ul {
    display: flex;
   flex-wrap: wrap;
    }
}
@media only screen and (max-width: 360px) {

  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;}
    .galleryItem  {
     width:140px;
     height: 200px;
     object-fit: cover;
   }
   .filterItem ul {
    display: flex;
   flex-wrap: wrap;
    }

}
@media only screen and (max-width: 280px) {

  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;}
    .galleryItem  {
      justify-content: center;
      align-items: center;
     width:105px;
     height: 200px;
     object-fit: cover;
   }
   .filterItem ul {
  display: flex;
 flex-wrap: wrap;
  }
}



.blml{
  display: flex;
  padding: 10px;
}
.btn-area{
display: block;
background-color: #A57C00;
color: white;
cursor: pointer;
font-weight: 700;
text-align: center;
align-items: center;
border-radius: 25px;
width: 100px;
height: 30px;
justify-content: center;
margin-left: 620px;
padding-top: 10px;

}
.btn-areaa{
  display: block;
  background-color:#A57C00;
  color: white;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  align-items: center;
  border-radius: 25px;
  width: 100px;
  height: 30px;
  justify-content: center;
 margin-left: 10px;
  padding-top: 10px;
  
  }

.filterItem ul {
  

  align-items: center;
  justify-content: center;



  list-style: none;
  padding: 0;
  gap: 10px;
  margin-bottom: 40px;
}
@media screen and (max-width:400px){


.galleryContainer {
 display: flex;
 flex-wrap: wrap;
 width: 100%;}
 .galleryItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
}
@media screen and (max-width:600px){


  .galleryContainer {
   display: flex;
   flex-wrap: wrap;
   width: 100%;}
   .galleryItem img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  }
  @media screen and (max-width:500px){


    .galleryContainer {
     display: flex;
     flex-wrap: wrap;
     width: 100%;}
     .galleryItem img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    }
    .filterItem{  width: 100%;}

.filterItem ul button {
  background:#4C275C;
  width: auto;
  color: white;
  padding: 8px 0px;
  text-transform: uppercase;
  border: navajowhite;
  font-size: 15px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  min-width: auto;
}

.filterItem ul button:hover {
  background: white;
  color: #000;
  cursor: pointer;
}

.galleryItem img {
  border-radius: 15px;
}
  
.ppbody{
  margin-top: 40px;

}

.pcontainer{
  margin: 30px 0px;
width: 100%;
display: flex;
}
.prow{
  width: 100%;
 
  justify-content: center;

  display: flex;
  flex-wrap: wrap;
}
.pimage{
  position: relative;
  flex: 1;

width: 100px;
margin: 10px;
overflow: hidden;
min-width: 380px;
height: 300px;
max-width: 480px;
background-color: #2E1834;


}
.pimage img{
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity;



}
.pimage:hover img{
  opacity: 10;

}
.pimage .pdetails{
  z-index: 1;
  position: absolute;
  top: 0;right: 0;
  color: #fff;
width: 100%;height: 100%;


}
.pdetails h2{
  text-shadow: 2px 2px #2E1834;

}
.pdetails p{
  text-shadow: 2px 2px #2E1834;

}
.pdetail{
  color: #A57C00;
  text-align: center;
  font-size: 35px;
  text-transform:uppercase;
  font-weight: 800;
  
}
.pimage .pdetails h2{
 text-align: center;
 font-size: 35px;
 text-transform:uppercase;
 font-weight: 500;
 margin-top: 70px;
 transition: 0.4s;
 transition-property: transform;
}
.pimage:hover .pdetails h2{
transform: translateY(-30px);
 }
 .pimage .pdetails p{
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity,transform;
 }
 .pimage:hover .pdetails p{
opacity: 1;
transform: translateY(-10px);
  }
  
  .pmore{
    position: absolute;
    background: rgba(255,255,255,1);
    width: 100%;
    display: flex;
  justify-content: space-between;
align-items: center;
padding: 15px 0;
bottom: -60px;
transition: 0.6s;
transition-property: bottom;

  }
  
  
  .pimage:hover .pmore{
    bottom: 0;
    
  }

  .pmore .pread-more {
    
  color:#A57C00;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
    width: 100%;

padding-left: 8px;
padding-right: 8px;
  }
 .picon-links{
  display: flex;
  justify-content:space-between;
  padding-right: 8px;
  padding-left: 8px;
 }

  @media(max-width:1080px){
    
    .pimage{
    
      max-width:380px;
 
    }}
   
    @media(max-width:480px){
      .pimage{
     
      min-width: 350px;
   
      
      
      }
      .pimage .pdetails p{
       font-size: 16px;
      }
      .pmore .pread-more , .pmore .icon-links a {
        font-size: 18px;
        
      }
  }
  













  


  /* Footer.css */
.footer-container {
  background: rgba(156, 101, 47, 0.89);
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.copyright {
  flex-basis: 100%;
  margin-bottom: 1rem;
}
.fotecontainer{
  background: rgba(156, 101, 47, 0.89);
  border-bottom: rgb(80, 79, 79) 1px solid;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;

}
.finfo{

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-right: 70px;

}
.finfo h1{
  color: white;
  padding-bottom: 10px;
}
.finfo h2{

  padding-bottom: 25px;
}
.fsocial{
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

margin-right: 70px;
}
.fsocial h1{
  color: #fff;

  padding-bottom: 10px;
}

.fsocial h2{

  padding-bottom: 10px;
}
.fsocial h3{

  padding-bottom: 25px;
}
.fsociall{
  display: flex;
}
.fsociall {
  padding-top:15px ;
 width: 25px;
 justify-content: center;
 align-items: center;

}


.fsociall img{
  width: 40px;
  height: 40px;
}
.fwhere{
 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.fwhere h1{
  color: #fff;
  padding-bottom: 10px;
}
.fwhere h3{
  padding-bottom: 25px;
}





/* The footer is fixed to the bottom of the page */



@media (max-height:800px) {
    footer {
        position: static;
    }
   
}

.footer-distributed {
  background: #2E1834;
  position: relative;
    box-sizing: border-box;
    width: 100%;

    font: bold 16px sans-serif;
    padding: 50px 50px 60px 50px;
    margin-top: 50px;
}

.footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h2 {
    color: #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
}


.footer-distributed h2 span {
  color:  #A57C00;
}

.footer-distributed h5 {

  color: #A57C00;
  font: normal 36px 'Cookie', cursive;
  margin: 0;
}


.footer-distributed h5 span {
  color: #ffffff;
}
/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 30px 0 12px;
 
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
    padding-left: 10px;
}

.footer-distributed .footer-company-name  {
    color: #8f9296;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}
.footer-distributed .footer-company-name  a{
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
a{
  text-decoration: none;
  list-style-type: none;
}
/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
    margin-left: 15px;
}

.footer-distributed .footer-center i {
   
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 25px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
  color:#A57C00;
    text-decoration: none;
    ;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 30%;
    margin-top: 0px;
  
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.footer-distributed .footer-icons a:hover {
  transform :translateY(-5px);
}

.footer-links a:hover {
    color:white;
}

@media (max-width: 880px) {
    .footer-distributed .footer-left, .footer-distributed .footer-center, .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }
    .footer-distributed .footer-center i {
        margin-left: 0;
    }
}       
        

        
/* 
.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
} */
/* .testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
} */
/* .testimonial .slick-arrow.slick-next {
  right: -70px;
} */
/* .testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
} */


/* .slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}
.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
} */





.ssection {
  width: 100%;
  margin: 5rem auto;
  max-width: 1170px;
  


}

@media screen and (min-width: 992px) {
  .ssection {
    width: 95%;
  }
}


.ssection-center {

  margin: 0 auto;
  margin-top: 4rem;
  width: 80%;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.sperson-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid hsla(48, 98%, 33%, 0.136);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}


.stitle {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: #767474;
  text-align: center;
  font-size: 35px;
}

.stitle {
  text-align: center;
  margin-bottom: 2rem;
}
.stitle h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.stext {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
color: black;
font-weight: 400;
}

.sprev,
.snext {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background:  #767474;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.sprev:hover,
.snext:hover {
  color:  #767474;
  background-color: #fff;
}
.sprev {
  left: 0;
}
.snext {
  right: 0;
}
@media (min-width: 800px) {
  .stext {
    max-width: 45em;
  }
  .sprev,
  .snext {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
.mvv-vis{
  margin-top: 80px;
}
.mvv-timeline ul{

  border-left: 4px solid rgba(62, 47, 32, 0.89);
  border-right: 4px solid rgba(62, 47, 32, 0.89);
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 50px;
  position: relative;
  padding: 5rem;
  list-style: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  transition: .4s ease;
}
.mvv-timeline:hover ul{

  transform: translateX(5px);
 
}
.mvv-timeline:hover ul{
  border-top: 4px solid rgba(62, 47, 32, 0.89);
  border-bottom: 4px solid rgba(62, 47, 32, 0.89);
  border-left:none;
  border-right:none;

  border-radius: 0.8rem;
 
}
.mvv-timeline ul li{
padding: 10px;
align-items: center;

}








/* .mapstyles{
background-image: url("./assets/img/map.png");
background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 400px;}
  .container {
    position: relative;
    min-height: 400px;
  } */
  .map__card {
    position: absolute;
    right: 10%;
    bottom: 10%;
    padding: 2rem;
    background: var(--deep-dark);
    width: 100%;
    max-width: 300px;
    border-radius: 12px;
  }
  .map__card__heading {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .map__card__link {
    display: inline-block;
    font-size: 1.6rem;
    margin-top: 3rem;
    text-decoration: underline;
  }
  @media only screen and (max-width: 768px) {
    .mapstyles{
    background-position:  80% center;
  }}
  @media only screen and (max-width: 400px) {
    .map__card {
      max-width: none;
      right: auto;
    }
  }



  .ptstyle{
    max-width: 500px;
    margin: 0 auto;
    font-size: 1.8rem;
    line-height: 1.3em;}
    @media only screen and (max-width: 768px) {
      .ptstyle{
      font-size: 1.4rem;}
    }





    .contactsectionstyle{
      padding: 10rem 0;}
      .contactSection__wrapper {
        display: flex;
        gap: 5rem;
        margin-top: 7rem;
        justify-content: space-between;
        position: relative;
      }
      .contactSection__wrapper::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 50%;
        background-color: var(--gray-1);
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
      }
      .left {
        width: 100%;
        max-width: 500px;
      }
      .right {
        max-width: 500px;
        width: 100%;
        border-radius: 12px;
        /* padding-left: 3rem; */
      }
      @media only screen and (max-width: 768px) {
        .contactSection__wrapper {
          flex-direction: column;
        }
        .contactSection__wrapper::after {
          display: none;
        }
        .left,
        .right {
          max-width: 100%;
        }
        .right {
          padding: 4rem 2rem 2rem 2rem;
        }
      }




.fromcontactsyle{
  width: 100%;}
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }
  label {
    font-size: 1.8rem;
  }
  input,
  textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--gray-1);
    background-color: var(--deep-dark);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
  }
  textarea {
    min-height: 250px;
    resize: vertical;
  }
  button[type='submit'] {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 2rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;
  }
.pmbod{
  margin-top: 10vh;
}
.gbod{
  margin-top: 12vh;
}

  .ccontactt{
    margin-top: 13vh;
    padding-bottom: 2rem;

  }
  .ccontact-headingg{
    display: flex;
    color:#A57C00;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
    padding-right: 2rem;

  }
  .ccontact-headingg h2{

    color:#2E1834;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
    position: relative;
    font-family: "Bai Jamjuree" , sans-serif;
  }
  .ccontact-headingg h2::before{

position: absolute;
top: 50%;
right: -80px;
content: '';
width: 70px;
height: 2px;


  }

  .ccontact-headingg h2::after{

    position: absolute;
    top: 50%;
    right: -80px;
    content: '';
    width: 70px;
    height: 2px;
    
    
      }
      .ccontainerr{
max-width: 1170px;
padding-left: 1rem;

padding-right: 1rem;
margin: auto;

      }
      .croww{

        display: flex;
        flex-wrap: wrap;
      }

.ccolumnn{
  flex: 0 0 auto;
  width: 50%;
}      
.ccontact-widgett{
  margin-bottom: 40px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


}
.ccontact-widgett-itemm{
  margin-bottom: 40px;
overflow: hidden;
align-items: center;
justify-content: center;
width: 480px;
}
.ciconn{
  height: 50px;
  width: 50px;
  background-color: #2E1834;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  margin-right: 30px;

  float: left;
}
.ciconn i{
font-size: 24px;
color: white;
position: relative;
top: 3px;
}
.ctextt{
  overflow: hidden;
}
.ctextt h5{
  color: #111;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6px;
}
.ctextt p{
  color: #111;
  font-size: 16px;
  font-family: "Bai Jamjuree" , sans-serif;
  font-weight: 400;
  margin: 0 0 15px 0;
  line-height: 28px;
}
.ccontact-formm{
  text-align: center;
  margin-bottom: 40px;
}
.ccontact-formm form{
display: block;
margin-top: 0em;
}
.cformm  input{
height: 46px;
width: 100%;
padding-left: 20px;
font-size: 15px;
color: #111;
border: 1px solid #2E1834;
margin-bottom: 20px;
border-radius: 4px;
transition: all 0.3s ;

  } 
  .cformm  textarea{
    height: 110px;
    width: 100%;
    padding-left: 20px;
    padding-top: 12px;
    font-size: 15px;
    color: #111;
    border: 1px solid #5d4a1f;
    margin-bottom: 9px;
    border-radius: 4px;
    transition: all 0.3s ;
    
      } 
 .cformm button{
  
  width:55%;
  cursor: pointer;
  background-color:  #2E1834;
  color: white;
  transition: 0.3s ease-in-out;
 }    
 .site-btn:hover button{

  cursor: pointer;
  color:  #5d4a1f;
  background-color: white;
  transition: 0.3s ease-in-out;
 }   
 .site-btn{
  border: none;
  display: inline-block;
  color: white;
  font-size: 14px;
  font-family:"Rubic", sans-serif;
  font-weight: 500;
border-radius: 50px;
  letter-spacing: 2px;
  padding: 12px 40px 10px;
 }
 .cmap-columnn{
  flex:0 0 auto;
  width: 100%;
 }
 .ccontact-mapp iframe{
width: 100%;

 }
 @media screen and (max-width:1024px){
  .ccontainerr{
    max-width: 960px;
  }
 }
 @media screen and (max-width:990px){
  .ccontainerr{
    max-width: 720px;
  }
 }


 @media screen and (max-width:630px){
  .ccontact-headingg{
   padding-right: 0;
  }
  .ccontact-headingg h2{
   font-size: 22px;
 
   }
   .ccontact-headingg h2::before{
  right: -60px;
  width: 50px;
    }
    .ccontact-headingg h2::after{
      left: -60px;
      width: 50px;
        }
   .cformm  input{
          width: 90%;
          display: flex;
          flex-direction: column;
          height: 26px;
          }
          .cformm  textarea{
            width: 90%;
            display: flex;
            flex-direction: column;
            height: 26px;
            }
            iframe{
              width:90%;
              
               }
 
 }

 @media screen and (max-width:500px){
  .ccontact-headingg{
   padding-right: 0;
  }
  .ccontact-headingg h2{
   font-size: 22px;
 
   }
   .ccontact-headingg h2::before{
  right: -60px;
  width: 50px;
    }
    .ccontact-headingg h2::after{
      left: -60px;
      width: 50px;
        }
   .cformm  input{
          width: 85%;
          display: flex;
          flex-direction: column;
          height: 26px;
          }
          .cformm  textarea{
            width: 85%;
            display: flex;
            flex-direction: column;
            height: 26px;
            }
            iframe{
              width:30px;
              
               }
 
 }
 @media screen and (max-width:768px){
  .ccontainerr{
    max-width: 540px;
  }
  .ccolumnn{
    width: 100%;
  }
 }
 




@media only screen and (max-width: 1280px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 1024px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 912px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 820px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 540px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 420px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 330px;
  }
}
@media only screen and (max-width: 414px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 330px;
  }
}

@media only screen and (max-width: 393px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 300px;
  }
}

@media only screen and (max-width: 375px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 250px;
  }
  .cformm button{
  
    width:65%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}
@media only screen and (max-width: 360px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 250px;
  }
  .cformm button{
  
    width:65%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}

@media only screen and (max-width: 280px) {
 
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 240px;
  }
  .cformm button{
  
    width:85%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}



  .template_Container{
    display: flex;
 
    width:100%;
margin-top: auto;
padding-bottom: 50px;
border-bottom:2px solid #111;
 
  }
 
.templatesearch{

  display: flex;




  width:100%;

  

 

}

.templatesearch  img {

  width: 250px;
  height: 150px;
}






.kitchenn {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  transition: 0.25s;
  background: #e1e1e1;
}


.colkitbedlic {
  background: #333;
  color: white;
}

.colcards {
  display: flex;
  max-width: 800px;
  width: calc(100% - 100px);
  height: 400px;
}

.colcards .colcard .colinfo .coltitle {
  font-weight: bold;
  font-size: 1.2rem;
}

.colcards .colcard .collabel .colicon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background-color: white;
  color: var(--color);
  border-radius: 50%;
}

.colcards .colcard .collabel .colinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  white-space: pre;
  transition: 1s var(--cb);
}

.colcards .colcard {
  position: relative;
  min-width: 60px;
  background: var(--bg);
  overflow: hidden;
  margin: 5px;
  background-size: auto 150%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s var(--cb);
  border-radius: 30px;
}

.colcards .colcard .collabel {
  display: flex;
  position: absolute;
  right: 0;
  height: 40px;
  transition: 0.5s var(--cb);
}

.colcards .colcard.colactive .collabel {
  bottom: 20px;
  left: 20px;
}

.colcards .colcard.colactive {
  flex-grow: 10000;
  background-size: auto 100%;
}

.colcards .colcard:not(.colactive) .collabel {
  bottom: 10px;
  left: 10px;
}

.colcards .colcard .colshadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(
    0, #0004, transparent
  );
}

.colcards .colcard.colactive .colinfo {
  opacity: 1;
}

.colcards .colcard:not(.colactive) .colinfo {
  opacity: 0;
}






.smsection {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.smcontainer {
  width: 100%;
height: 100%;
margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.smright {
  width: 50%;

  background-color: rgb(118, 95, 52);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: white;
  margin-left: -150px;
}
.smright h1 {
  font-size: 50px;
  font-weight: 600;

}
.smright p {
  margin: 20px 0;
  font-weight: 400;
  line-height: 25px;
}


@media only screen and (max-width: 768px) {
  .smcontainer {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
  .smleft {
    width: 100%;
    height: 150px;
  }
  .smright {
    width: 90%;
    margin: 0;
    margin-top: -90px;
  }
}



@media  screen and (max-width:500px) {
 

  .contentv{

    
  
    width: 125px;
    height: 150px;
 
    
    }
    .contentcv p{
      font-size: 2px;
      font-weight: 300;
      text-align: center;
   
    }
    .mainv video{
      width: 50%;
      height: 50%;
      object-fit: cover;
    
    
    
    }
    
}

.mainv{
  width: 100%;
  height: 100vh;
  margin-top: 0;
  text-align: center;
   align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;


}
.mainv video{
  width: 100%;
  height: 100%;
  object-fit: cover;



}
.contentv{
position: absolute;

top: 50%;
left: 50%;
width: 450px;
height: 300px;
background-color: transparent;
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(50px);
transform: translate(-50%,-50%);
border-radius: 25px;
padding: 15px 2%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
color: white;
text-align: center;

}
.contentcv p{
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.pmish h1{
  color: #767474;
  text-align: center;
  font-size: 35px;
  text-transform:uppercase;
  font-weight: 800;
  font-family: 'Times New Roman', Times, serif;
}
.pmishh h1{
  color: #767474;
  text-align: center;
  font-size: 35px;
  text-transform:uppercase;
  font-weight: 800;
  font-family: 'Times New Roman', Times, serif;
}
.pdetail h1{
  color: #767474;
  text-align: center;
  font-size: 35px;
  text-transform:uppercase;
  font-weight: 800;
  font-family:normal;
}
/*////////*/
.mbanner-bod{
height: 100vh;
width: 100%;

}
.mbanner-text{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
    align-items: center;
    justify-content: center;
position: relative;
}

.mbanner-text h1{
  font-size: 40px;
  color: #fff;
}

.mbanner-text1 p{
  font-family: merienda;
  padding: 10px 2;
}
.mbanner-text2 p{
 
  
  font-family: merienda;
  padding: 10px 2px;


}
/* .mbanner-img{
 background-image: url(./assets/img/miss.webp); 
-webkit-background-size:cover;
background-size: cover;
object-fit: cover;
background-repeat: no-repeat;
height: 100vh;
width: 100%;
background-position: center center;
filter: blur(5px);
} */
.mbanner-text1 {
position: absolute;
top: 25%;
left: 50%;
transform: translate(-50%,-50%);
z-index: 5;
width: 600px;
padding: 40px 20px;
text-align: center;
border-top-left-radius: 70px;
border-bottom-right-radius: 70px;
background-color: rgba(0, 0, 0, 0.3);
color: #fff;

border: 8px outset #6A3C99 ;
backdrop-filter: blur(3px);
box-shadow: 0px 0px 36px 0px;
}
.mbanner-text2 {
position: absolute;
top: 65%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 5;
  width: 650px;
  padding: 40px 10px;
  text-align: center;
  border-top-left-radius: 70px;
  border-bottom-right-radius: 70px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  border: 8px inset #6A3C99 ;
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 36px 0px;
  
  }


 

  




@media only screen and (max-width: 1280px) {
  .mbanner-text1 {
    top: 20%;
    height: 80px;
    width: 700px;
    }
    .mbanner-text2 {width: 100%;
      height: 400px;
      top: 65%;
   width: 750px;
   padding: 0;
   
    }
    .mbanner-text1 p{

      font-size: 20px;
    }
    .mbanner-text2 p{

      font-size: 25px;
    }

    .mbanner-text h1{
      font-size: 30px;
    }
    .mbanner-text1 h1{
      font-size: 30px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 1024px) {
  .mbanner-text1 {
    top: 15%;
    height: 80px;
    width: 700px;
    }
    .mbanner-text2 {width: 100%;
      height: 360px;
      top: 65%;
   width: 760px;
   padding: 0;
   
    }
    .mbanner-text1 p{

      font-size: 20px;
    }
    .mbanner-text2 p{

      font-size: 25px;
    }

    .mbanner-text h1{
      font-size: 30px;
    }
    .mbanner-text1 h1{
      font-size: 30px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 912px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 680px;
    }
    .mbanner-text2 {width: 100%;
      height: 450px;
      top: 65%;
   width: 700px;
   padding: 0;
   
    }
    .mbanner-text p{

      font-size: 25px;
    }

    .mbanner-text h1{
      font-size: 30px;
    }
    .mbanner-text1 h1{
      font-size: 30px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 820px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 680px;
    }
    .mbanner-text2 {width: 100%;
      height: 450px;
      top: 65%;
   width: 700px;
   padding: 0;
   
    }
    .mbanner-text p{

      font-size: 25px;
    }

    .mbanner-text h1{
      font-size: 30px;
    }
    .mbanner-text1 h1{
      font-size: 30px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 768px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 580px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 65%;
   width: 600px;
   padding: 0;
   
    }
    .mbanner-text p{

      font-size: 20px;
    }

    .mbanner-text h1{
      font-size: 30px;
    }
    .mbanner-text1 h1{
      font-size: 30px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 650px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 350px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 70%;
   width: 350px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 540px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 380px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 70%;
   width: 400px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 420px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 280px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 65%;
   width: 280px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 414px) {
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 280px;
    }
    .mbanner-text2 {width: 100%;
      height: 350px;
      top: 65%;
   width: 300px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
    .mbanner-text p{
      font-size: 15px;
     
    }
}

@media only screen and (max-width: 393px) {
  
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 280px;
    }
    .mbanner-text2 {width: 100%;
      height: 450px;
      top: 65%;
   width: 300px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
  }


@media only screen and (max-width: 375px) {
  .mbanner-text p{
    font-size: 15px;
  }
  .mbanner-text1 {
    top: 15%;
    height: 140px;
    width: 240px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 65%;
   width: 280px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}
@media only screen and (max-width: 360px) {
  .mbanner-text p{
    font-size: 15px;
  }
  .mbanner-text1 {
    top: 20%;
    height: 160px;
    width: 230px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 70%;
   width: 260px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}

@media only screen and (max-width: 280px) {
  
  .mbanner-text1 {
    top: 15%;
    height: 110px;
    width: 220px;
    padding-top: 8px;
    }
    .mbanner-text p{
      font-size: 15px;
    }
    .mbanner-text2 {width: 100%;
      height: 380px;
      top: 65%;
   width: 225px;
   padding: 0;
   
    }
    .mbanner-text h1{
      font-size: 20px;
    }
    .mbanner-text1 h1{
      font-size: 20px;
      margin-bottom: 10px;
    }
}


.iconflag{
  width: 2rem;
}





@media only screen and (max-width: 1280px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 1024px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 912px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 820px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 540px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 400px;
  }
}
@media only screen and (max-width: 420px) {
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 330px;
  }
}
@media only screen and (max-width: 414px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 330px;
  }
}

@media only screen and (max-width: 375px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 250px;
  }
  .cformm button{
  
    width:65%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}
@media only screen and (max-width: 360px) {

  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 250px;
  }
  .cformm button{
  
    width:65%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}

@media only screen and (max-width: 280px) {
 
  .ccontact-widgett-itemm{
    margin-bottom: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 240px;
  }
  .cformm button{
  
    width:85%;
    cursor: pointer;
    background-color:  #2E1834;
    color: white;
    transition: 0.3s ease-in-out;
   } 
}
.foter .wave{



}
.foter .wave #wave1{

z-index: 1000;
opacity: 1;
bottom: 0;
animation: animateWave 4s linear infinite;
}
@keyframes animateWave{
  0%{
    background-position-x: 1000px;
  }
  100%{
    background-position-x: 0px;
  }
}









 .tube h1{
  transform: translate(-20%, -300%);
  position: absolute;
  font-size: 3em;
  letter-spacing: px;
  color: #2E1834;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0008);
 line-height: 0.70em;
 outline:none;
 animation: animate 1s linear infinite;
}
@keyframes animate {
  0%,18%,20%,50.1%,60%,65.1%,80%,90.1%,92%
  {
      color: #2E1834;
      text-shadow: none;
  }
  18.1%,20.1%,30%,50%,60%,100%
  {
      color: #fff;
      text-shadow: 0 0 10px #2E1834,
      0 0 20px #2E1834,
      0 0 40px #2E1834,
      0 0 80px #2E1834, 
      0 0 160px #2E1834;
     
  }
}






.skewedvn{
  width: 100%;

  display: flex;

  margin: 0em 0em;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
}
.textvn{
 padding: 3.5em;
 width: 50%;
 display: flex;
 justify-content:space-evenly;
 align-items: flex-start;
 flex-direction: column;
}
.imagevn{
  width: 100%;
  height: 20em;
  position: relative;
  overflow: hidden;
}

.imagevn::before{
  content: '';
  position: absolute;
  left: -9.1em;
  top: 50%;

width: 40%;
height: 250%;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);

}
.skewed-left .imagevn::before{
left: initial;
right: -9.1em;
}
.imagevn img{
  width: 140%;

}
@media screen and (max-width:750px){
  .skewedvn{
    display: block;
    width: 20em;
  }
  .imagevn .textvn{
    width: 100%;
  }
  .imagevn img{
    width: 160%;
  
  }
  .skewedvn .imagevn::before{
    width: 120%;
    height: 40%;
    top: -5em;
    left: 50%;
    transform: translateY(-50%)rotate(10deg);

  }
  .skewed-left .imagevn::before{

    transform: translateY(-50%)rotate(10deg);
top: initial;
bottom: -5em;


}}



.diagonal-layout {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.diagonal-section {
  background-color: #333;
  color: #fff;
  padding: 20px;
  transform: skew(-20deg);
}

.images-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.image-container {
  overflow: hidden;
  height: 200px;
}

.image-container img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}


.ppbodym{
  margin-top: 15px;

}

.pcontainerm{
  margin: 30px 0;
width: 100%;
display: flex;
}
.prowm{
  width: 100%;
 
  justify-content: center;

  display: flex;
  flex-wrap: wrap;
}
.pimagem{
  position: relative;
  flex: 1;

width: 100px;
margin: 10px 10px;
overflow: hidden;
min-width: 700px;
height: 500px;
max-width: 480px;


}
.pimagem img{
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: relative;
  vertical-align: top;
  transition: 0.6s;

  transition-property: opacity;
  opacity: 10;

}
.pimagem:hover img{
  opacity: 10;
  filter: blur(5px);


}
.pimagem .pdetailsm{
  z-index: 1;
  position: absolute;
  top: 0;right: 0;
  color: #fff;
width: 100%;height: 100%;
}
.pdetailm{
  color: #A57C00;
  text-align: center;
  font-size: 35px;
  text-transform:uppercase;
  font-weight: 800;
}
.pimagem .pdetailsm h2{
 text-align: center;
 font-size: 35px;
 text-transform:uppercase;
 font-weight: 500;
 margin-top: 70px;
 transition: 0.4s;
 transition-property: transform;
}
.pimagem:hover .pdetailsm h2{
transform: translateY(-30px);
 }
 .pimagem .pdetailsm p{
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: 0.6s;
 
  padding: 1.5em 1em;
  text-shadow: 2px 2px #000;
  transition-property: opacity,transform;
 }
 .pimagem:hover .pdetailsm p{
opacity: 1;
transform: translateY(80px);
  }
  
  .pmorem{
    position: absolute;
    background: rgba(255,255,255,1);
    width: 100%;
    display: flex;
  justify-content: space-between;
align-items: center;
padding: 15px 0;
bottom: -60px;
transition: 0.6s;
transition-property: bottom;

  }
  
  
  .pimagem:hover .pmorem{
    bottom: 0;
    
  }

  .pmorm .pread-morem {
    
  color:#A57C00;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
    width: 100%;

padding-left: 8px;
padding-right: 8px;
  }
 .picon-linksm{
  display: flex;
  justify-content:space-between;
  padding-right: 8px;
  padding-left: 8px;
 }

 .pdetailll{
  color: #A57C00;
  text-align: center;
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 20px;
}


.gcontain{
display: flex;
margin: 0 10px;

}
.gimg{
  flex: 1;
}
.gdet{
  flex: 1;
display: flex;
flex-direction: column;
padding: 0 1rem;

}

.gdethh {
  width: 50%;
  max-height: 4vh;
text-align: start;
justify-content: center;
align-items: center;
margin-top: 100px;
flex: 1;
padding: 10px;
}
.gdeth {
  width: 50%;
  max-height: 10vh;
text-align: start;
justify-content: center;
align-items: center;
margin-top: 30px;
flex: 1;
padding: 10px;
}
.gdeth h1,p{


  }

.gdeti{

  flex: 1;
}
.gdeti img{
width: 100%;
height: 300px;
}









@media only screen and (max-width: 1280px) {
  .service__box {
     padding: 40px 20px;
width: 130px;
margin: 10px 20px;





  }
  .serrow{
display: flex;

display: flex;
flex-wrap: wrap;

  }
}
@media only screen and (max-width: 1024px) {
  .service__box {
     padding: 55px 14px;
width: 110px;
margin: 10px 15px;





  }
  .serrow{
display: flex;

display: flex;
flex-wrap: wrap;

  }
}

@media only screen and (max-width: 912px) {
  .service__box {
     padding: 40px 10px;
width: 200px;
margin: 10px 40px;





  }
  .serrow{
display: flex;

display: flex;
flex-wrap: wrap;

  }
}
@media only screen and (max-width: 820px) {
  .service__box {
     padding: 40px 10px;
width: 170px;
margin: 10px 40px;





  }
  .serrow{
display: flex;

display: flex;
flex-wrap: wrap;

  }
}
@media only screen and (max-width: 768px) {
  .gdethh {
 
margin-top: 0px;

  }
}
@media only screen and (max-width: 540px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}
@media only screen and (max-width: 420px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}
@media only screen and (max-width: 414px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}
@media only screen and (max-width: 414px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
    .gdeth {
      width: 50%;
      min-height: 40vh;
    
    }
}

@media only screen and (max-width: 393px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
    .gdeth {
      width: 50%;
      min-height: 40vh;
    
    }
}

@media only screen and (max-width: 375px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}
@media only screen and (max-width: 360px) {

  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}

@media only screen and (max-width: 280px) {
  .gcontain{
    display: flex;
 flex-direction: column;
    
    }
}


/* 
.wrapperdun {
  background-image: url("./assets/img/hig4.jpg");
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
} */






.wrapperdun .container {
  max-width: 50%;
  top: 150px;
  margin: auto;
  background-color: white;
  border-radius: 25px;
}

.wrapperdun .item {

  padding: 50px;
  height: 50vh;


  border: 1px solid #fff;
  border-radius: 25px;

 

}


.wrapperdun .heading {
  text-align: center;
  color: #4C275C;
}
.wrapperdun .heading h1{
  text-align: center;
  margin-bottom: 3px;
  padding: 10px;
}
.wrapperdun .heading p{
  text-align: center;
  margin-top: 10px;
  padding: 10px;
}
.scrollll{
  background-color: #5d4a1f;


}

.containergal{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.mediacontainer{
width: 95%;
min-height: 100vh;
position: relative;
margin: 0 auto;
align-items: center;
justify-content: center;
margin-top: 15vh;
}
.mediacontainer .media-container{

display: grid;
grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
gap: 15px;
margin: 20px auto;

}
.mediacontainer .media-container .media{
  height: 220px;
  width: 100%;
  border: 5px solid #2E1834;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  cursor: pointer;
}
.mediacontainer .media-container .media :is(video,img){
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.3 linear;
}
.mediacontainer .media-container .media:hover :is(video,img){
transform: scale(1.1);
}
.mediacontainer .popup-media{
position: fixed;
top: 25vh;
z-index: 100;
align-items: center;
justify-content: center;
height: 70vh;
width: 90%;

}
.mediacontainer .popup-media :is(video,img){

  position: absolute;
  top: 50%;
left: 55%;
transform: translate(-50%,-50%);
display: block;
max-width: 90%;
max-height: 100%;
object-fit: cover;
border: 3px solid #2E1834;
align-items: center;
}
.mediacontainer .popup-media span{
  position: absolute;
  top: 0%;
  left: 95%;
font-size: 50px;
font-weight: bolder;
z-index: 100;
cursor: pointer;
color: #fff;
user-select: none;


}

/* filter project */
.galleryItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.galleryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 0;
}

.galleryWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 0%;
 
}

.filterItem ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 20px;
  margin-bottom: 30px;
}

.filterItem ul button {
  color: #767474;
  color: #fff;
  padding: 4px 15px;
  text-transform: uppercase;
  font-size: 17px;
  border-radius: 5px;
  border: none;
}

.filterItem ul button:hover {
  background: white;
  cursor: pointer;
  color: black;
  transition: all 1s ease-in-out;
}

.galleryItem img {
  border-radius: 15px;
}

/* 319*/
@media screen and (max-width:319px){
.galleryContainer{
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
}
.filterItem ul button {
padding: 1px 4px;
font-size: 10px;
}
.filterItem ul {
  gap: 8px;
  margin-bottom: 20px;
}
}
/* 320-480 */
@media only screen and (max-width:480px) and (min-width:320px){
  .galleryContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
  }
  .filterItem ul button {
    padding: 2px 6px;
    font-size: 12px;
  }
   .filterItem ul {
      gap: 8px;
      margin-bottom: 20px;
    }
} 
/* 481-768 */
@media only screen and (max-width:720px) and (min-width:480px){
  .galleryContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterItem ul button {
    padding: 2px 6px;
    font-size: 20px;
 display: grid;
  }
   .filterItem ul {
      gap: 8px;
      margin-bottom: 30px;margin-left: 30px;
    }
    .galleryItem img {
      border-radius: 15px;
      max-width: 160px;
    }
}
@media only screen and (max-width:1024px) and (min-width:721px){
  .galleryContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterItem ul button {
    padding: 2px 6px;
    font-size: 20px;
 display: grid;
  }
   .filterItem ul {
      gap: 8px;
      margin-bottom: 30px;margin-left: 30px;
    }
    .galleryItem img {
      border-radius: 15px;
      max-width: 160px;
    }
}
@media  screen and (max-width:532px) {
  .galleryContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .filterItem ul button {
    padding: 2px 6px;
    font-size: 10px;
 display: grid;
  }
   .filterItem ul {
      gap: 8px;
      margin-bottom: 30px;margin-left: 30px;
    }
    .galleryItem img {
      border-radius: 15px;
      max-width: 160px;
    }
}





/* single gallary page */

.thumbnails img{
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.active-thumbnail{
  border: #2E1834 3px solid;
}

.toppContentt {
  margin-top: 13vh;
  font-size: 1.5rem;
  text-align: center;
  color: #4C275C;
}


/* gallary */

.galcontainergal {
  margin-top: 12vh;
  display: block;
  width: 100%;
  overflow:hidden;
  position: relative;

}
.toppContenttt {
  margin-bottom: 2vh;
  font-size: 1.5rem;
  text-align: center;
  color: #4C275C;
}
.galcard-wrappergal{
position: relative;
width: 100%;
padding: 0 5px;
margin:0 -22px;
box-sizing: border-box;
justify-content: center;
align-items: center;
}
.galcard-wrappergal:active{
  outline: none;
  border: none;
}
.galcard-wrappergal:hover{
  outline: none;
  border: none;
}
.galcard-wrappergal:focus{
  outline: none;
  border: none;
}
.galcardgal{
  width: 100%;
  height: 450px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 0 20px;
  
}
.galcard-imagegal{
  width: 100%;
  height: 450px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2E1834;
  justify-content: center;
  align-items: center;
  z-index: 2;
transition: 0.5s;
}
.galcard-imagegal img{
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2E1834;
object-fit: cover;
  z-index: 2;
transition: 0.5s;

}
.galcard-imagegal:hover img{
  opacity: 0.4;
  transition: 0.5s;

}
.galcard-imagegal:hover{
  transform: translateY(-5px);
  transition: all 0.9s;
}

.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: transparent;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel .slick-prev {
  margin: 0 35px;
  border-radius: 50%;
}
.carousel .slick-next {
  margin: 0 35px;
  border-radius: 50%;
}
.carousel.slick-prev:before, .slick-next:before {
  color: #2E1834;
}


     
@media only screen and (max-width: 1024px) {
 
  .galcardgal{
    height: 350px;
  }
  .galcard-imagegal{
    height: 350px;
  }
  .ssslick-sslide{
    width:150px;
  }
   .ssslick-sslide img{
   
    padding:0 30px ;
    height:130px;
    width:220px;
    border-radius: 25%;
 } 
}
@media only screen and (max-width: 960px) {
 
  .galcardgal{
    height: 350px;
  }
  .galcard-imagegal{
    height: 350px;
  }
  .ssslick-sslide{
    width:150px;
  }
   .ssslick-sslide img{
   
    padding:0 30px ;
    height:130px;
    width:200px;
    border-radius: 25%;
 } 


}

@media only screen and (max-width: 540px) {

  .galcardgal{
    height: 225px;
  }
  .galcard-imagegal{
    height: 225px;
  }
  .ssslick-sslide{
    width:150px;
  }
   .ssslick-sslide img{
    padding:0 30px ;
    height:130px;
    width:200px;
    border-radius: 25%;
 } 

}
@media only screen and (max-width: 414px) {

  .galcardgal{
    height: 225px;
  }
  .galcard-imagegal{
    height: 225px;
  }
  .ssslick-sslide{
    width:150px;
  }
   .ssslick-sslide img{
   
    padding:0 0px ;
    height:130px;
    width:180px;
    border-radius: 25%;
 } 

}
@media only screen and (max-width: 411px) {
 
  .galcardgal{
    height: 230px;
  }
  .galcard-imagegal{
    height: 230px;
  }
  .ssslick-sslide{
    width:150px;
  }
   .ssslick-sslide img{
   
    padding:0 15px ;
    height:130px;
    width:155px;
    border-radius: 25%;
 } 


}
@media only screen and (max-width: 375px) {

  .galcardgal{
    height: 225px;
  }
  .galcard-imagegal{
    height: 225px;
  }
  .ssslick-sslide{
    width:150px;

  }
   .ssslick-sslide img{
   
    padding:0 0px ;
    height:120px;
    width:160px;
    border-radius: 25%;
 } 

}

/* thumb gallary example */

.galcard-wrappergalt{
position: relative;
width: 100%;
padding: 0 5px;
margin:0 -80px;
box-sizing: border-box;
justify-content: center;
align-items: center;
}
.galcard-wrappergalt:active{
  outline: none;
  border: none;
}
.galcard-wrappergalt:hover{
  outline: none;
  border: none;
}
.galcard-wrappergalt:focus{
  outline: none;
  border: none;
}
.galcardgalt{
  width: 280px;
  height: 190px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 0 100px;
  justify-content: center;
  align-items: center;
  
}
.galcard-imagegalt{
  width: 280px;
  height: 190px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2E1834;
  justify-content: center;
  align-items: center;
  z-index: 2;
transition: 0.5s;
}
.galcard-imagegalt img{
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2E1834;
object-fit: cover;
  z-index: 2;
transition: 0.5s;

}
.galcard-imagegalt:hover img{
  opacity: 0.4;
  transition: 0.5s;

}
.galcard-imagegalt:hover{
 
  transition: all 0.9s;
}

/* thumbgallary midya */
@media only screen and (max-width: 1024px) {
 
  .galcardgalt{
    height:100px;
    width:170px;
  }
  .galcard-imagegalt{
    height:100px;
    width:170px;
  }
  .galcard-wrappergalt{
    margin:0 -60px;
    }
}
@media only screen and (max-width: 960px) {
 
  .galcardgalt{
    height:100px;
    width:170px;
  }
  .galcard-imagegalt{
    height:100px;
    width:170px;
  }
  .galcard-wrappergalt{
    margin:0 -60px;
    }

}

@media only screen and (max-width: 540px) {

  .galcardgalt{
    height:100px;
    width:190px;
  }
  .galcard-imagegalt{
    height:100px;
    width:190px;
  }
  .galcard-wrappergalt{
    margin:0 -70px;
    }


}
@media only screen and (max-width: 414px) {

  .galcardgalt{
    height:90px;
    width:150px;
  }
  .galcard-imagegalt{
    height:90px;
    width:150px;
  }
  .galcard-wrappergalt{
    margin:0 -80px;
    }

 

}
@media only screen and (max-width: 411px) {
 
  .galcardgalt{
    height:90px;
    width:150px;
  }
  .galcard-imagegalt{
    height:90px;
    width:150px;
  }
  .galcard-wrappergalt{
    margin:0 -80px;
    }


}
@media only screen and (max-width: 375px) {

  .galcardgalt{
    height:90px;
    width:150px;
  }
  .galcard-imagegalt{
    height:90px;
    width:150px;
  }
  .galcard-wrappergalt{
    margin:0 -80px;
    }


}

/* pre loader */
.spinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2E1834;
}
.spinner span {
  font-size: 3rem;
  padding-right: 1rem;
  color: #A57C00;
 
  font: normal 36px 'Cookie', cursive;
}



/* services home */
.servfheading{

  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align:justify;
  
  }
  
  .ser-iconss{
     display: flex;
     margin-top: 20px;
     align-items: center;
     justify-content: center;
   }
   .servicef{
     margin: 4rem 0rem;
     
   }
  
   
  
  
   .ser-iconss i{
     color:#A57C00;
     padding-left: 15px;
     font-size: 30px;
     align-items: center;
     padding-top: 2px;
   }
   .pdetaill{
   color: #A57C00;
   text-align: center;
   align-items: center;
   font-size: 25px;
   text-transform:uppercase;
   font-weight: 800;
  }
  .servcontainer {
     display: flex;
     flex-wrap: wrap;
     width: 100%;
     justify-content: center;
     margin-top: 30px;
    
  }
   .service__box {
     align-items: center;
      border-radius: 10px;
      width: 160px;
      height: 120px;
      align-items: center;
      justify-content: start;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 30px 31px;
      transition: .4s ease;
      margin:10px 8px;
      border: 1px  solid #4C275C;
     background-image: linear-gradient(120deg, #1b1b1a00, #bab9b5df);
   }
   .service__box:hover {
      transform: translateY(15px);
      background-color: #6A3C99;
   }
   .service__box:hover p {
     color: #fff;
  }
   .icon {
      font-size: 50px;
      margin-bottom: 10px;
      display: inline-flex;
      color: rgba(93, 63, 32, 0.89);
      
    
   }
   .icon img{
     width: 1em;
     height: 1em;
   }
   
  
  
   @media only screen and (min-width: 1280px) {
    .service__box {
       padding: 40px 20px;
  width: 130px;
  margin: 10px 20px;


 

 
 
 
    }
    .serrow{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
  
  @media only screen and (max-width: 1280px) {
     .service__box {
        padding: 40px 20px;
   width: 130px;
   margin: 10px 20px;
 
  
 
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 1024px) {
     .service__box {
        padding: 55px 14px;
   width: 110px;
   margin: 10px 15px;

  
  

     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 912px) {
     .service__box {
        padding: 40px 10px;
   width: 200px;
   margin: 10px 40px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 905px) {
     .service__box {
        padding: 40px 10px;
   width: 170px;
   margin: 10px 15px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 820px) {
     .service__box {
        padding: 40px 10px;
   width: 170px;
   margin: 10px 40px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 817px) {
     .service__box {
        padding: 40px 12px;
   width: 150px;
   margin: 10px 12px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 768px) {
     .service__box {
        padding: 40px 10px;
   width: 150px;
   margin: 10px 40px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 755px) {
     .service__box {
        padding: 40px 12px;
   width: 140px;
   margin: 10px 10px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 733px) {
     .service__box {
        padding: 40px 10px;
   width: 140px;
   margin: 10px 10px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 727px) {
     .service__box {
        padding: 40px 12px;
   width: 130px;
   margin: 10px 12px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 719px) {
     .service__box {
        padding: 40px 14px;
   width: 120px;
   margin: 10px 13px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 703px) {
     .service__box {
        padding: 55px 20px;
   width: 110px;
   margin: 10px 10px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 544px) {
     .service__box {
        padding: 40px 13px;
   width: 120px;
   margin: 10px 15px;
  
  
  
  
     }
    
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 540px) {
     .service__box {
        padding: 40px 13px;
   width: 120px;
   margin: 10px 15px;
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 420px) {
     .service__box {
        padding: 60px 30px;
   width: 120px;
     }
     .serrow{
  display: flex;
  flex-wrap: wrap;
     
     
     }
  }
  @media only screen and (max-width: 414px) {
     .service__box {
        padding: 40px 15px;
   width: 150px;
   margin: 10px 12px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 414px) {
     .service__box {
        padding: 40px 15px;
   width: 140px;
   margin: 10px 16px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 407px) {
     .service__box {
        padding: 40px 15px;
   width: 130px;
   margin: 10px 10px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  
  @media only screen and (max-width: 393px) {
     .service__box {
        padding: 40px 13px;
   width: 140px;
   margin: 10px 12px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 383px) {
     .service__box {
        padding: 40px 22px;
   width: 118px;
   margin:5px 10px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 375px) {
     .service__box {
        padding: 40px 22px;
   width: 121px;
   margin:5px 10px;
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
     }
  }
  @media only screen and (max-width: 360px) {
     .service__box {
        padding: 40px 70px;
   width: 110px;
   margin: 10px 60px;
  justify-content: center;
  align-items: center;
  
  
  
     }
     .serrow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
     }
  }
  
  @media only screen and (max-width: 280px) {
     .service__box {
        padding: 40px 30px;
   width: 190px;
   margin:5px 40px;
  
  
  
  
  
     }
     .serrow{
  display: flex;
  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
     }
  }




  /* serv navbar */

  .ser-iconss{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  .serviceffff{
    margin-top: 12vh;
    
  }
  .pdetailfds{
    color: #A57C00;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-align:justify;
    text-transform: none;
    padding: 10px 25px
  }
 
  .servcontainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
   
 }
  .servicefff{
   margin: 6rem 2.5rem;
 }
  .ser-iconss i{
    color:#A57C00;
    padding-left: 15px;
    font-size: 30px;
    align-items: center;
    padding-top: 2px;
  }
 
 
  .service__boxf {
    align-items: center;
     border-radius: 10px;
     width: 280px;
     height: 180px;
     align-items: center;
     text-align: center;
     justify-content: center;
     padding: 15px 31px;
     transition: .4s ease;
     margin:10px 8px;
     border: 1px  solid #A57C00;
    background-image: linear-gradient(120deg, #1b1b1a00, #bab9b5df);
  }
  .service__boxf:hover {
     transform: translateY(15px);
     background-color: #A57C00;
  }
  .service__boxf:hover p {
    color: #fff;
 }
  .iconf {
     font-size: 50px;
     margin-top: 10px;
     margin-bottom: 40px;
     display: inline-flex;
     color: rgba(93, 63, 32, 0.89);;
  }
  .iconf img{
    width: 1em;
    height: 1em;
  }
  
 
 
 
 
  @media only screen and (max-width: 1280px) {
    .service__boxf {
       padding: 40px 20px;
  width: 130px;
  margin: 10px 20px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 1024px) {
    .service__boxf {
       padding: 25px 14px;
  width: 130px;
  margin: 10px 5px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 912px) {
    .service__boxf {
       padding: 40px 10px;
  width: 200px;
  margin: 10px 40px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 905px) {
    .service__boxf {
       padding: 40px 10px;
  width: 170px;
  margin: 10px 15px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 820px) {
    .service__boxf {
       padding: 40px 10px;
  width: 170px;
  margin: 10px 40px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 817px) {
    .service__boxf {
       padding: 40px 12px;
  width: 150px;
  margin: 10px 12px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 768px) {
    .service__boxf {
       padding: 40px 10px;
  width: 150px;
  margin: 10px 40px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 755px) {
    .service__boxf {
       padding: 40px 12px;
  width: 140px;
  margin: 10px 10px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 733px) {
    .service__boxf {
       padding: 40px 10px;
  width: 140px;
  margin: 10px 10px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 727px) {
    .service__boxf {
       padding: 40px 12px;
  width: 130px;
  margin: 10px 12px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 719px) {
    .service__boxf {
       padding: 40px 14px;
  width: 120px;
  margin: 10px 13px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 703px) {
    .service__boxf {
       padding: 35px 20px;
  width: 120px;
  margin: 10px 6px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 544px) {
    .service__boxf {
       padding: 40px 13px;
  width: 120px;
  margin: 10px 15px;
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
   
    
 }
 @media only screen and (max-width: 540px) {
    .service__boxf {
       padding: 40px 13px;
  width: 120px;
  margin: 10px 15px;
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
    .pdetailfds{
      
       text-align: center;
       font-size: 8px;
       font-weight: 500;
       text-align:justify;
       text-transform: none;
       padding: 10px 25px;
   
 
     }
 }
 @media only screen and (max-width: 420px) {
    .service__boxf {
       padding: 60px 30px;
  width: 120px;
    }
    .serrowf{
 display: flex;
 flex-wrap: wrap;
    
    
    }
 }
 @media only screen and (max-width: 414px) {
    .service__boxf {
       padding: 40px 15px;
  width: 150px;
  margin: 10px 12px;
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 414px) {
    .service__boxf {
       padding: 40px 15px;
  width: 140px;
  margin: 10px 16px;
 
 
 
    }
    
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 407px) {
    .service__boxf {
       padding: 40px 15px;
  width: 130px;
  margin: 10px 10px;
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 
 @media only screen and (max-width: 393px) {
    .service__boxf {
       padding: 40px 13px;
  width: 140px;
  margin: 10px 12px;
 
 
 
    }
  
    .serrowf{
 display: flex;
 
 display: flex;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 383px) {
    .service__boxf {
       padding: 40px 22px;
  width: 118px;
  margin:5px 10px;
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 375px) {
    .service__boxf {
       padding: 40px 22px;
  width: 121px;
  margin:5px 10px;
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 
    }
 }
 @media only screen and (max-width: 360px) {
    .service__boxf {
       padding: 40px 70px;
  width: 110px;
  margin: 10px 60px;
 justify-content: center;
 align-items: center;
 
 
 
    }
    .serrowf{
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 justify-content: center;
 
    }
 }
 
 @media only screen and (max-width: 380px) {
    .service__boxf {
       padding: 20px 30px;
  width: 130px;
  margin:5px 25px;
 
 
 
 
 
    }
    .serrowf{
 display: flex;
 
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 
    }
 }



 /* visson mission */

 .paternmv{  
  position: relative;
    width: 350px;
    height: 350px;
    background-color: #4C275C;
  opacity: 0.2;
  border-radius:50%;
  justify-content: center;
  align-items: center;
    box-shadow: 0px 0px 50px 0px;
  
  
  }
  .paternmv:hover{  
   
      background-color: #a5a5a5;
  transition: 1s ease-in-out all;
    
    }
  
  
  .partt{
      padding: 1rem 1rem; 
      display: flex;
      border: 5px  solid #4C275C;
      background-image: linear-gradient(120deg, #1b1b1a00, #bab9b5df);
      border-radius:25px;
      margin:10px 5px;
  
     
    }
    .partWrapper{
        display: flex;
    flex-direction: column;
  
   
    flex: 1;
    }
    .dfimg{
      display: flex;
    }
  
    .partt1{
        display: flex;
        align-items: center;
        justify-content: center;
    }
   
    .part1Wrapper{
        gap:2rem;
        display: flex;
        border-left: none;
        justify-content: space-between;
        align-items: center;
    }
    .part1Wrapper:hover {
      transform: translateY(-20px);
      color: #fff;
     
    }
    .part11Wrapper{
  
      border-left: none;
      gap:2rem;
      display: flex;
  flex-direction: column;
      justify-content: space-between;
      align-items: center;
  }
  .part11Wrapper:hover {
    transform: translateY(-20px);
   
  }
    .leftPart{
  
      padding: 0.5rem;
     
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .leftPartt{
    
      padding: 0.5rem;
  opacity: 1;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .leftPart h1{
      color: #4C275C;
      font-size: 3rem;
      text-transform: capitalize;
      font-family: 'Dancing Script', cursive;
      font-weight: 500;
    }
    .leftPartt h1{
     
      color: #4C275C;
      font-size: 3rem;
      text-transform: capitalize;
      font-family: 'Dancing Script', cursive;
      font-weight: 500;
      margin-bottom: 2.5rem;
    }
    .leftPart p{
        color:#4C275C;
        font-size: 1rem;
        font-weight: 600;
        font-family: '';
        margin-bottom: 0.2rem;
        text-shadow: 1px 1px #fff;
      
      }
      .leftPartt p{
        text-shadow: 1px 1px #fff;
        color:#4C275C;
        font-size: 1rem;
        font-weight: 600;
        font-family: '';
       
        margin-bottom: 0.2rem;
    
      }
  
      .rightPart{
  
  
        padding: 2rem;
        width: 250px; 
        height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .leftPart img{
     
        align-items: center;
        justify-content: center;
 
     height: 100%;
     width: 200px; 
     height: 200px;
     filter: drop-shadow(30px 10px 4px #4C275C);
      }
      .leftPartt img{
        align-items: center;
        align-items: center;
  justify-content: center;
  filter: drop-shadow(30px 10px 4px #4C275C);
  
    
        height: 100%;
        width: 200px; 
        height: 200px;
        opacity: 10;
      
         }
  
      .arightmv .img-acontainermv{
    
        display: grid;
        grid-template-columns: repeat(12,1fr);
        position: relative;
        align-items: center;
        text-align: center;
       }
      
       .arightmv img{
        border-radius: 25px;
       max-width: 90%;
       border: 1px solid #333;
       }
       .arightmv .topmv{
         grid-column: 1 / span 8;
         grid-row: 1;
         padding-top: 25%;
     
         z-index: 6;
       }
       .arightmv .bottommv{
         grid-column: 4 /  -1;
         grid-row: 1;
        
         z-index: 5;
       }
       
     
   
      @media only screen and  (max-width:662px) and (min-width:334px) {
        .partt{
     
      flex-direction:column;
    }
     .rightPart{
        width: 300px;   
      }
      }
      @media screen and (max-width: 333px){
        .rightPart{
          width: 200px;   
        }
        .partt{
     
          flex-direction:column;
        }
      }
      @media screen and (max-width:320px){
        .leftPart h1{
          font-size: 2rem;
          padding: 0 1rem;
        }
        .leftPart p{
            font-size: 1.3rem;
            padding: 0 1rem;
          }
      }




      /* navbar  */
      .navbar {
        background: #2E1834;
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 26px 0px;
        font-weight: 600;
      width: 100%;
      height: 8vh;
      min-height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        font-size: 1rem;
        top: 0;
        left: 0;
      z-index: 999;
      opacity: 0.8;
      
      }
      
      
      .navbar-logo img{
      
        
      
        justify-self: start;
      width:70px !important;
      height:60px !important;
        cursor: pointer;
       object-fit: cover;
      
      
      }
      
      .fa-firstdraft {
        margin-left: 0.5rem;
        font-size: 1.6rem;
      }
      
      
      .nav-item {
        display: flex;
        align-items: center;
        height: 80px;
      }
      
      .nav-links span {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        border-radius: 25px;
       
     
      }
      
      
      
      .nav-menu {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-gap: 10px;
        list-style: none;
        text-align: center;
        width: 70vw;
        justify-content: end;
        margin-right: 2rem;
      }
      
      .fa-bars {
        color: #fff;
      }
      
      .nav-links-mobile {
        display: none;
      
      }
      
      .menu-icon {
        display: none;
      }
      @media screen and (min-width: 960px) {
      
        .nav-menu {
          display: grid;
          grid-template-columns: repeat(18, auto);
          grid-gap: 10px;
          list-style: none;
          text-align: center;
          width: 100vw;
          left: 0;
          right: 0;
          justify-content: end;
          margin-right: 2rem;
        }
        .nav-links:hover  span {
          background-color: white;
            color: #767474;
        
     
          border-radius: 4px;
          transition: all 0.2s ease-out;
        }
      }
      @media screen and (max-width: 960px) {
        .NavbarItems {
          position: relative;
        }
      
        .nav-menu {
          background: #2E1834;
          backdrop-filter: blur(10px);
          box-shadow: 0px 0px 0px 0px;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 60vh;
        left:  0;
          position: absolute;
         
        
          
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
        
      
        }
      
        .nav-menu.activee {
      
      
          left: 0;
          opacity: 0.9;
          transition: all 0.5s ease;
          z-index: 999;
          height: 60vh;
          transform: translateY(-250vh);
        }
      
        .nav-links {
          top: 0;
          text-align: center;
          padding: 1rem;
          width: 100%;
          display: table;
        }
      
        .nav-links:hover {
       
      
          color: #767474;
      
      
        border-radius: 4px;
        transition: all 0.2s ease-out;
        }
      
        .navbar-logo img{
        height: 3rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 20%);
        }
      
        .menu-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
          cursor: pointer;
        }
      
        .fa-times {
          color: #fff;
          font-size: 2rem;
        }
      
        .nav-links-mobile {
          display: block;
          text-align: center;
          padding: 1.5rem;
        
          border-radius: 4px;
          width: 80%;
         
          text-decoration: none;
          color: #fff;
          font-size: 1.5rem;
        }
      
        .nav-links-mobile:hover {
          background: #fff;
       
          transition: 250ms;
        }
      
        button {
          display: none;
        }
      
        
      }
      @media screen and (max-width: 960px) {
        .NavbarItems {
          position: relative;
        }
      
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 60vh;
          position: absolute;
          top: 74px;
          left:  0;
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
       
       
        }
      
        .nav-menu.activee {
          background: #2E1834;
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 0px 0px;
          left: 0;
          opacity: 0.9;
          transition: all 0.5s ease;
          z-index: 999;
          height: 50vh;
        }
      
        .nav-links {
          top: 0;
          text-align: center;
          padding: 1rem;
          width: 100%;
          display: table;
        }
      
        .nav-links:hover  span {
          background-color: white;
        
            color: #767474;
        
        
          border-radius: 4px;
          transition: all 0.2s ease-out;
        }
      
        .navbar-logo img{
        height: 3rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 20%);
        }
      
        .menu-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
          cursor: pointer;
        }
      
        .fa-times {
          color: #fff;
          font-size: 2rem;
        }
      
        .nav-links-mobile {
          display: block;
          text-align: center;
          padding: 1.5rem;
        
          border-radius: 4px;
          width: 80%;
         
          text-decoration: none;
          color: #fff;
          font-size: 1.5rem;
        }
      
        .nav-links-mobile:hover {
          background: #fff;
       
          transition: 250ms;
        }
      
        button {
          display: none;
        }
      
        
      }
      @media screen and (max-width: 540px) {
        .NavbarItems {
          position: relative;
        }
      
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 60vh;
      
          position: absolute;
          top: 74px;
          left:  0;
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
       
       
        }
      
        .nav-menu.activee {
          background: #2E1834;
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 0px 0px;
          left: 0;
          opacity: 0.9;
          transition: all 0.5s ease;
          z-index: 999;
          height: 62vh;
        }
      
        .nav-links {
          top: 0;
          text-align: center;
          padding: 1rem;
          width: 100%;
          display: table;
        }
      
        .nav-links:hover {
         
      
          color: #767474;
      
      
        border-radius: 4px;
        transition: all 0.2s ease-out;
        }
      
        .navbar-logo img{
        height: 3rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 20%);
        }
      
        .menu-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
          cursor: pointer;
        }
      
        .fa-bars {
          color: #fff;
          font-size: 2rem;
        }
      
        .nav-links-mobile {
          display: block;
          text-align: center;
          padding: 1.5rem;
        
          border-radius: 4px;
          width: 80%;
         
          text-decoration: none;
          color: #fff;
          font-size: 1.5rem;
        }
      
        .nav-links-mobile:hover {
          background: #fff;
       
          transition: 250ms;
        }
      
        button {
          display: none;
        }
      
        
      }
      @media screen and (max-width: 375px) {
        .NavbarItems {
          position: relative;
        }
      
        .nav-menu {
        
          display: flex;
          flex-direction: column;
          width: 100%;
        height: 60vh;
          position: absolute;
          top: 74px;
          left:  0;
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
       
       
        }
      
        .nav-menu.activee {
          background: #2E1834;
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 0px 0px;
          left: 0;
          opacity: 0.9;
          transition: all 0.5s ease;
          z-index: 999;
          height: 62vh;
        }
      
        .nav-links {
          top: 0;
          text-align: center;
          padding: 1rem;
          width: 100%;
          display: table;
        }
      
        .nav-links:hover {
       
      
          color: #767474;
      
      
        border-radius: 4px;
        transition: all 0.2s ease-out;
        }
      
        .navbar-logo img{
        height: 3rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 20%);
        }
      
        .menu-icon {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
          cursor: pointer;
        }
      
        .fa-times {
          color: #fff;
          font-size: 2rem;
        }
      
        .nav-links-mobile {
          display: block;
          text-align: center;
          padding: 1.5rem;
        
          border-radius: 4px;
          width: 80%;
         
          text-decoration: none;
          color: #fff;
          font-size: 1.5rem;
        }
      
        .nav-links-mobile:hover {
          background: #fff;
       
          transition: 250ms;
        }
      
        button {
          display: none;
        }
      
        
      }
      @media screen and (max-width: 320px) {
        .navbar-logo img{
          height: 3rem;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(25%, 4%);
          }
          .fa-times {
            color: #fff;
            font-size: 2rem;
        align-items: center;
        justify-content: center;
          }
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 60vh;
          position: absolute;
          top: 74px;
          left:  0;
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
       
       
        }}
      @media screen and (max-width: 280px) {
      
      .navbar-logo img{
        height: 3rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(25%, 10%);
        }
        .nav-menu {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 60vh;
          position: absolute;
          top: 74px;
          left:  0;
          opacity: 1;
          z-index: 999;
          transition: all 0.5s ease;
       
       
        }}


        /* drop dwon  */
        .dropdown-menu {

          width: 125px;
        position: absolute;
        display: flex;
        
          top: 8vh;
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
          opacity: 1;
        
          background: #2E1834;
        }
        .dropdown-link :hover{
          transform: scale(1.5);
        }
        
        .dropdown-menuu {
        
          width: 140px;
          position: absolute;
        flex-direction: column;
          top: 8vh;
          list-style: none;
          text-align: start;
          z-index: 999;
          opacity: 1;
          background: #2E1834;
        }
        
        .dropdown-links:hover {
        background-color: white;
        width: fit-content;
        color: #767474;
        
        
        border-radius: 4px;
        transition: all 0.2s ease-out;
        }
        .dropdown-links {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          color: white;
          text-decoration: none;
          padding: 6px 8px;
          align-items: start;
          justify-content: start;
          text-align: start;
          z-index: 999;
          top: 60;
        
         
        }
        
        .dropdown-link {
          transition: transform .2s;
          display: block;
          height: 100%;
          width: 50%;
          text-decoration: none;
          color:rgba(230, 165, 101, 0.89) ;
          padding: 2px 8px;
          align-items: center;
          justify-content: center;
          z-index: 999;
          top: 60;
         
        }
        
        .dropdown-link img{
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
         
        }
        
        
        @media screen and (max-width: 960px) {
         
          .dropdown-link {
            transition: transform .2s;
            display: block;
            height: 100%;
            width: 100%;
            text-decoration: none;
            color:rgba(230, 165, 101, 0.89) ;
            padding: 2px 8px;
            align-items: center;
            justify-content: center;
            z-index: 999;
            top: 60;
           
          }
          .dropdown-menu {
        
            width: 100%;
            position:absolute;
          display: flex;
          
            top: 60vh;
            list-style: none;
            text-align: center;
            z-index: 999;
            opacity: 1;
        
        
          
          }
        }
   










        